import "./style.scss";

export const LogoCard = (props) => {
  return (
    <div className="logo_card_container">
      <span className="card_header_text">
        <props.logo />
      </span>
      <span className="card_sub_text">{props.text}</span>
    </div>
  );
};
