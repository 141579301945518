import React, { useState, useRef } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { ReactComponent as Sch1 } from "../../../../assets/svg/mobile/scheme/1.svg";
import { ReactComponent as Sch2 } from "../../../../assets/svg/mobile/scheme/2.svg";
import { ReactComponent as Sch3 } from "../../../../assets/svg/mobile/scheme/3.svg";
import { ReactComponent as Sch4 } from "../../../../assets/svg/mobile/scheme/4.svg";
import { ReactComponent as Sch5 } from "../../../../assets/svg/mobile/scheme/5.svg";
import { ReactComponent as Sch6 } from "../../../../assets/svg/mobile/scheme/6.svg";

import "./style.scss";

const schemeItems = [
  <Sch1 width={"100%"} height={"100%"} />,
  <Sch2 width={"100%"} height={"100%"} />,
  <Sch3 width={"100%"} height={"100%"} />,
  <Sch4 width={"100%"} height={"100%"} />,
  <Sch5 width={"100%"} height={"100%"} />,
  <Sch6 width={"100%"} height={"100%"} />,
];

export const MobileScheme = () => {
  const [showAllElements, setShowAllElements] = useState(false);
  const firstElementRef = useRef(null);

  const displayItems = showAllElements ? schemeItems : schemeItems.slice(0, 1);

  const handleShowAllElements = () => {
    setShowAllElements(!showAllElements);
    firstElementRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="mobile_scheme_container">
        {displayItems.map((item, index) => (
          <span
            ref={index === 0 ? firstElementRef : null}
            key={index}
            className="scheme_block"
          >
            {item}
          </span>
        ))}
      </div>
      <span className="collapse_array" onClick={handleShowAllElements}>
        {showAllElements ? (
          <span>
            Hide <UpOutlined style={{ fontSize: 11 }} />
          </span>
        ) : (
          <span>
            See all stages <DownOutlined style={{ fontSize: 11 }} />
          </span>
        )}
      </span>
    </>
  );
};
