import { useMediaQuery } from "usehooks-ts";
import { ReactComponent as HeaderImage } from "../../../assets/svg/header-image.svg";
import { ReactComponent as MobileHeaderImage } from "../../../assets/svg/mobile-header-image.svg";

import "./style.scss";

export const Header = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 860px)");

  return (
    <header className="header_container">
      <span className="header_text_container">
        <span className="header_text">
          Prediction <br />
          {isMobile ? null : "\u2003\u2003"}
          of molecular <br />
          biological {isMobile ? <br /> : null} activity
        </span>
        <span className="sub_text">
          qsar.me experts team is here to give
          <br /> your business accurate solutions
        </span>
      </span>
      <span className="header_logo_container">
        {isTablet ? <MobileHeaderImage /> : <HeaderImage />}
      </span>
    </header>
  );
};
