export const columns1 = [
  "ROC-AUC metric",
  "Our approach",
  "Smi2Vec*-LSTM",
  "Smi2Vec-BiGRU",
  "TranGRU",
];
export const rows1 = [
  ["NR.AhR", "0.904", "0.879", "0.832", "-"],
  ["NR.AR", "0.771", "0.691", "0.711", "0.824"],
  ["NR.AR.LBD", "0.747", "-", "-", "0.847"],
  ["NR.Aromatase", "0.802", "0.496", "0.699", "0.784"],
  ["NR.ER", "0.787", "0.623", "0.736", "0.691"],
  ["NR.ER.LBD", "0.763", "0.531", "0.868", "0.843"],
  ["NR.PPAR.gamma", "0.767", "0.566", "0.749", "0.838"],
  ["SR.ARE", "0.795", "0.641", "0.761", "0.701"],
  ["SR.ATAD5", "0.806", "0.5", "0.763", "0.727"],
  ["SR.HSE", "0.796", "0.612", "0.784", "0.736"],
  ["SR.MMP", "0.951", "0.743", "0.86", "0.816"],
  ["SR.p53", "0.818", "0.518", "0.732", "0.81"],
];
