import { Route, Routes } from "react-router-dom";

import Landing from "./pages/landing";
import { Navbar } from "./components/navbar/Navbar";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Landing />} />
      </Routes>
    </>
  );
}

export default App;
