export const columns3 = ["Toxicity endpoint", "Number of samples"];
export const rows3 = [
  ["Developmental toxicity", " 6 537"],
  ["Retinoic acid receptor (RAR) pathway", " 8 196"],
  ["Blood Brain Barrier Penetration", " 2 039"],
  ["Cardiotoxicity/hERG inhibition", " 21 682"],
  ["Carcinogenicity", " 7 665"],
  ["Endocrine system disruption", " 12 707"],
  ["Mouse / Rat / Rabbit / Guinea Pig Intraperitoneal LD50", " 81 450"],
  ["Intraperitoneal LD0", " 4 202"],
  ["Mouse / Rat / Rabbit / Guinea Pig Intravenous LD50 ", "35 925"],
  ["LDToxDB  Rat Oral LD50 ", "2 746"],
  ["Mouse / Rat / Rabbit / Guinea Pig Intravenous LD0", " 1 067"],
  ["Mouse / Rat / Rabbit / Guinea Pig Oral LD50", " 32 326"],
  ["Mouse / Rat / Rabbit / Guinea Pig Oral LD0", " 4 696"],
  ["Mouse / Rat / Rabbit / Guinea Pig Subcutaneous LD50 ", "32 253"],
  ["Mouse / Rat / Rabbit / Guinea Pig Subcutaneous LD0 ", "1 047"],
  ["Mouse / Rat / Rabbit / Guinea Pig Skin LD50 ", "36 818"],
  ["Mouse / Rat / Rabbit / Guinea Pig Skin LD0", " 8 070"],
  ["Ames test / Mutagenicity", " 6 500"],
  ["40 hour Tetrahymena pyriformis IGC50 ", "1 079"],
  ["48 hour Daphnia magna LC50 ", "1 007"],
  ["96 hour Fathead Minnow LC50 ", "4 120"],
];
