import { useMediaQuery } from "usehooks-ts";

import { ReactComponent as CatalystLogo } from "../../assets/svg/logos/catalyst.svg";
import { ReactComponent as ChemrarLogo } from "../../assets/svg/logos/chemrar.svg";
import { ReactComponent as RosatomLogo } from "../../assets/svg/logos/rosatom.svg";
import { ReactComponent as NusLogo } from "../../assets/svg/logos/nus.svg";

import { ReactComponent as Dollar } from "../../assets/svg/advantages/dollar.svg";
import { ReactComponent as Graph } from "../../assets/svg/advantages/graph.svg";
import { ReactComponent as Umbrella } from "../../assets/svg/advantages/umbrella.svg";
import { ReactComponent as Search } from "../../assets/svg/advantages/search.svg";

import { ReactComponent as Question } from "../../assets/svg/help/question.svg";
import { ReactComponent as Reload } from "../../assets/svg/help/reload.svg";
import { ReactComponent as Db } from "../../assets/svg/help/db.svg";
import { ReactComponent as Message } from "../../assets/svg/help/message.svg";

import { rows1, columns1 } from "../../components/UI/table/table1";
import { rows2, columns2 } from "../../components/UI/table/table2";
import { rows3, columns3 } from "../../components/UI/table/table3";
import { rows4, columns4 } from "../../components/UI/table/table4";

import "./styles.scss";
import { PersonCard } from "../../components/UI/person-card/PersonCard";
import { ArticleCard } from "../../components/UI/article-card/ArticleCard";
import { Form } from "../../components/form";
import { Table } from "../../components/UI/table/Table";
import { HorizontalBlock } from "../../components/UI/horizontal-block/HorizontalBlock";
import { TextPhotoCard } from "../../components/UI/text-photo-card/TextPhotoCard";
import { HelpCard } from "../../components/UI/help-card/HelpCard";
import { LogoCard } from "../../components/UI/logo-card/LogoCard";
import { Header } from "../../components/UI/header/Header";
import { MobileScheme } from "../../components/UI/mobile/scheme/MobileScheme";
import { ArrowsBlock } from "../../components/UI/arrows-block/ArrowsBlock";
import { MobileTable } from "../../components/UI/mobile/table/MobileTable";

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const Landing = () => {
  const isTablet = useMediaQuery("(max-width: 860px)");
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <div className="page">
      <div className="page_container">
        {/* <section id="header" className="header_box"> */}
        <section id="header">
          <Header />
        </section>
        {/* </section> */}
        {/* <div className="content_box"> */}
        <div className="content_container">
          <section
            id="qsar"
            className="text_content_block"
            style={{ gap: 16, paddingTop: 32 }}
          >
            <span className="block_header_text_container">
              <span className="block_header_text">QSAR</span>
            </span>
            <span className="block_header_sub_text">
              It is a computational modeling technique for predicting biological
              activity based on structural features of compounds. Involves
              analysis of chemical structures, their corresponding activities,
              and training and validation of ML models
              <br />
              <br />
              QSAR models use databases of existing compounds, activity data,
              and molecular descriptors or their graph-based embeddings to
              capture structural characteristics. Main goal of models are
              compound prioritization and reduction of time/costs in drug
              discovery
            </span>
          </section>
          <section
            className="content_block"
            style={{ gap: isTablet ? 0 : undefined }}
            id="technology"
          >
            <span className="block_header_text">Complex approach</span>
            {isTablet ? <MobileScheme /> : <HorizontalBlock />}
          </section>
          <section className="text_content_block" id="advantages">
            <span className="block_header_text_container">
              <span className="block_header_text">Advantages</span>
            </span>
            <span className="advantage_cards_container">
              <TextPhotoCard
                photo={Dollar}
                header="Reduced research and development costs"
                text="qsar.me can help you to reduce your research and development costs by 5-6 times. With our product, you can focus your testing on the chemicals that are most likely to be successful, saving you time and money"
              />
              <TextPhotoCard
                photo={Graph}
                header="Improved efficiency of manufacturing processes"
                text="qsar.me can help you to improve the efficiency of your manufacturing processes by up to 20%. With our product, you can optimize the conditions for synthesizing and processing chemicals, saving you money and improving the quality of your products"
              />
              <TextPhotoCard
                photo={Umbrella}
                header="Improved safety"
                text="qsar.me can help you to improve the safety of your products. With our product, you can identify chemicals that are toxic or environmentally harmful, avoiding their release into the environment and protecting your customers from harm"
              />
              <TextPhotoCard
                photo={Search}
                header="New IP value"
                text="We can additionally check your product for patent clearance and conduct a preliminary patent search"
              />
            </span>
          </section>
          <section className="content_block" id="cooperation">
            <span className="block_header_text">
              Terms of cooperation
              <br />
              (service delivery)
            </span>
            <ArrowsBlock />
          </section>
          <section id="metrics" className="content_block">
            <span className="block_header_text">Metrics</span>
            <span className="tables_container">
              <span className="table_container">
                <span className="table_header">Acute toxicity benchmarks</span>
                {isTablet ? (
                  <MobileTable rowsData={rows2} columnsData={columns2} />
                ) : (
                  <Table columns={columns2} rows={rows2} />
                )}
              </span>
              <span className="table_container">
                <span className="table_header">Tox21 dataset benchmarks</span>
                {isTablet ? (
                  <MobileTable
                    rowsData={rows1}
                    columnsData={columns1}
                    isSecondActive
                  />
                ) : (
                  <Table
                    columns={columns1}
                    rows={rows1}
                    maxWidth={690}
                    isSecondActive
                  />
                )}
              </span>
            </span>
          </section>
          <section
            id="our-datasets"
            className="content_block"
            style={{ gap: 0 }}
          >
            <span className="block_header_text">
              If you need data for datasets,
              <br /> you can use our
            </span>
            <span className="help_container">
              <span className="helpful_container">
                <span className="help_header">
                  Why it can be helpful for you
                </span>
                <span className="helpful_cards_container">
                  <HelpCard
                    icon={Question}
                    text={"You do not have\nnecessary data at all"}
                  />
                  <HelpCard
                    icon={Reload}
                    text={"Reproducing is very\n important for you"}
                  />
                  <HelpCard
                    icon={Db}
                    text={"You want to enriched\n your own datasets"}
                  />
                  <HelpCard
                    icon={Message}
                    text={"You need information\n for ADMET"}
                  />
                </span>
              </span>
              <span className="help_tables_container">
                <span className="table_container">
                  <span className="table_header">Our ADMET datasets</span>
                  <Table columns={columns3} rows={rows3} maxWidth={485} />
                </span>
                <span className="table_container">
                  <span className="table_header">QSAR datasets</span>
                  <Table columns={columns4} rows={rows4} maxWidth={485} />
                </span>
              </span>
            </span>
          </section>
          <section section id="achievements" className="content_block">
            <span className="block_header_text">Case studies</span>
            <span className="help_container" style={{ marginTop: 0 }}>
              <span className="helpful_container">
                <span
                  className={
                    isMobile
                      ? "mobile_logos_container"
                      : "helpful_cards_container"
                  }
                >
                  <LogoCard
                    logo={CatalystLogo}
                    text={
                      "By taking part in Open Catalyst Challenge competition, our architectures won 2’nd place behind Microsoft Research Team by the accuracy in adsorption energy prediction task. OCP remains the largest quantum chemical dataset of various catalyst structures for renewable energy processing and storage with SOTA graph neural networks included"
                    }
                  />
                  <LogoCard
                    logo={ChemrarLogo}
                    text={
                      "Screening of MCL-1 inhibitors (in partnership with Chemrar HTC)\n\n Myeloid leukemia 1 (MCL-1) is an antiapoptotic protein of the BCL-2 family that prevents apoptosis by binding to the pro-apoptotic BCL-2 proteins"
                    }
                  />
                  <LogoCard
                    logo={NusLogo}
                    text={
                      "Screening of the properties of defects in 2D material using sparse representation graph neural network. We compare our methodology with the state-of-the-art approaches and demonstrate at least 3.7 times energy prediction error drop. Also, proposed approach is an order of magnitude more resource-efficient than SOTAs both for the training and inference part"
                    }
                  />
                  <LogoCard
                    logo={RosatomLogo}
                    text={
                      "Virtual screening of GCPII Inhibitors\n \nWe performed QSAR and docking based screening of 10M molecules against GCPII (Prostate cancer target). Active learning technique allowed to decrease docking by 20 times"
                    }
                  />
                </span>
              </span>
            </span>
          </section>

          <section id="articles" className="content_block">
            <span className="block_header_text">Articles</span>
            <span className="articles_container">
              <ArticleCard
                header="Unveiling the complex structure-property correlation of defects in 2D materials based on high throughput datasets"
                text="npj 2D Materials and Applications, 2023"
                link="https://www.nature.com/articles/s41699-023-00369-1"
              />
              <ArticleCard
                header="Geometric Deep Learning for Design of Catalysts and Molecules"
                text="Advanced studies in Artificial Intelligence and machine learning, 2023"
                link="https://www.mathnet.ru/php/archive.phtml?wshow=paper&jrnid=danma&paperid=338&option_lang=eng"
              />
              <ArticleCard
                header="The open catalyst challenge 2021: Competition report"
                text="Proceedings of Machine Learning Research, PMLR, 2022"
                link="https://proceedings.mlr.press/v176/das22a.html"
              />
              <ArticleCard
                header={
                  "Sparse representation for machine learning the properties of defects in 2D materials"
                }
                text="npj Computational Materials, 2023"
                link="https://www.nature.com/articles/s41524-023-01062-z"
              />
              <ArticleCard
                header="Deep learning metal complex properties with natural quantum graphs"
                text="Digital Discovery (2023)"
                link="https://pubs.rsc.org/en/content/articlehtml/2023/dd/d2dd00129b"
              />
              <ArticleCard
                header="Revising Message Passing Graph Neural Networks for Catalyst Design"
                text="Chemrxiv, 2022"
                link="https://chemrxiv.org/engage/chemrxiv/article-details/624bee76437a0607dbf1a34e"
              />
            </span>
          </section>
          {/* </div> */}
        </div>
        {/* <section id="contact" className="form_box"> */}
        <section id="contact" className="form_block_container">
          <span className="form_header_text_container">
            <span className="block_header_text">Contact us</span>
          </span>
          <Form />
          <span className="form_footer_container">
            <span className="block_footer_email_text">
              contact.qsar.me@gmail.com
            </span>
            <span className="block_footer_text">
              © {getCurrentYear()} qsar.me
            </span>
          </span>
        </section>
        {/* </section> */}
      </div>
    </div>
  );
};

export default Landing;
