import { HashLink } from "react-router-hash-link";
import { Dropdown } from "antd";
import { ButtonComp } from "../button/Button";
import { ReactComponent as Logo } from "../../assets/svg/navbar/logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/navbar/menu.svg";
import { ReactComponent as ContactIcon } from "../../assets/svg/navbar/contact.svg";
import { useMediaQuery } from "usehooks-ts";
import "./navbar.scss";

export const Navbar = () => {
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const isMobile = useMediaQuery("(max-width: 600px)");

  const items = [
    {
      label: (
        <HashLink smooth to={"/#qsar"}>
          QSAR
        </HashLink>
      ),
      key: "0",
    },
    {
      label: (
        <HashLink smooth to={"/#technology"}>
          Technology
        </HashLink>
      ),
      key: "1",
    },
    {
      label: (
        <HashLink smooth to={"/#advantages"}>
          Advantages
        </HashLink>
      ),
      key: "2",
    },
    {
      label: (
        <HashLink smooth to={"/#cooperation"}>
          Cooperation
        </HashLink>
      ),
      key: "3",
    },
    {
      label: (
        <HashLink smooth to={"/#metrics"}>
          Metrics
        </HashLink>
      ),
      key: "4",
    },
    {
      label: (
        <HashLink smooth to={"/#our-datasets"}>
          Datasets
        </HashLink>
      ),
      key: "5",
    },
    {
      label: (
        <HashLink smooth to={"/#achievements"}>
          Cases
        </HashLink>
      ),
      key: "6",
    },
    {
      label: (
        <HashLink smooth to={"/#articles"}>
          Articles
        </HashLink>
      ),
      key: "7",
    },
  ];

  return (
    <nav className={"navbar"}>
      {isTablet ? (
        <span className="navbar_container">
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            className="icon-styles"
          >
            <span style={{ width: isMobile ? undefined : isTablet ? 104 : undefined }}>
              <MenuIcon />
            </span>
          </Dropdown>
          <HashLink smooth to="#header" className="logo_container">
            <Logo />
          </HashLink>
          {isMobile ? (
            <HashLink smooth to={"/#contact"} className="icon-styles">
              <ContactIcon />
            </HashLink>
          ) : (
            <HashLink smooth to={"/#contact"}>
              <ButtonComp
                buttonClassName="btn_antd"
                type="text"
                buttonSize="large"
              >
                Contact us
              </ButtonComp>
            </HashLink>
          )}
        </span>
      ) : (
        <span className="navbar_container">
          <span className="navbar_logo-menu">
            <HashLink smooth to="#header" className="logo_container">
              <Logo />
            </HashLink>
            <span className="list_container">
              <ul>
                <li>
                  <HashLink smooth to={"/#qsar"}>
                    QSAR
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to={"/#technology"}>
                    Technology
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to={"/#advantages"}>
                    Advantages
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to={"/#cooperation"}>
                    Cooperation
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to={"/#metrics"}>
                    Metrics
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to={"/#our-datasets"}>
                    Datasets
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to={"/#achievements"}>
                    Cases
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to={"/#articles"}>
                    Articles
                  </HashLink>
                </li>
              </ul>
            </span>
          </span>
          <span style={{ height: "100%" }}>
            <HashLink smooth to={"/#contact"}>
              <ButtonComp
                buttonClassName="btn_antd"
                type="text"
                buttonSize="large"
              >
                Contact us
              </ButtonComp>
            </HashLink>
          </span>
        </span>
      )}
    </nav>
  );
};
