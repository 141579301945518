import React, { useState } from "react";
import { ReactComponent as Open } from "../../../../assets/svg/mobile/table/down.svg";
import { ReactComponent as Close } from "../../../../assets/svg/mobile/table/right.svg";
import "./style.scss";

const RowInfo = ({ rowData, columnData, isSecondActive }) => {
  const info = rowData.slice(1).map((item, index) => (
    <span
      key={index}
      className={`mobile_table_item_content ${
        isSecondActive ? "content_active" : ""
      }`}
      // className={
      //   isSecondActive
      //     ? "mobile_table_item_content_active"
      //     : "mobile_table_item_content"
      // }
    >
      <span className="column_name">{columnData[index + 1]}</span>
      <span>{item}</span>
    </span>
  ));

  return <div className="mobile_table_content">{info}</div>;
};

export const MobileTable = ({ rowsData, columnsData, isSecondActive }) => {
  const [activeRows, setActiveRows] = useState([]);

  const handleRowClick = (index) => {
    setActiveRows((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div>
      {rowsData.map((row, index) => (
        <div
          key={index}
          onClick={() => handleRowClick(index)}
          className="mobile_table_container"
        >
          <span className="mobile_table_header">
            {activeRows[index] ? <Open /> : <Close />}
            {row[0]}
          </span>
          {activeRows[index] && (
            <RowInfo
              rowData={row}
              columnData={columnsData}
              isSecondActive={isSecondActive}
            />
          )}
        </div>
      ))}
    </div>
  );
};
