import { useMediaQuery } from "usehooks-ts";
import "./style.scss";

export const Input = (props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <span className="input_container">
      {props.comment && isMobile ? (
        <textarea
          className={props.error ? "input-error" : ""}
          type={props.type}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.handleChange}
        />
      ) : (
        <input
          className={props.error ? "input-error" : ""}
          type={props.type}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.handleChange}
        />
      )}

      {props.error && <span className="error-message">{props.error}</span>}
    </span>
  );
};
