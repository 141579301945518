import "./style.scss";

export const TextCard = (props) => {
  return (
    <div className="text_card_container">
      <span className="card_header_text">{props.header}</span>
      <span className="card_sub_text">{props.text}</span>
    </div>
  );
};
