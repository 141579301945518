import "./style.scss";

export const HelpCard = (props) => {
  return (
    <div className="help_card_container">
      <div className="icon_container">
        <props.icon />
      </div>
      <div className="help_text_card_container">
        <span className="card_sub_text">{props.text}</span>
      </div>
    </div>
  );
};
