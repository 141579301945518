import React from "react";

import { ReactComponent as Left } from "../../../assets/svg/scheme/left.svg";
import { ReactComponent as Right } from "../../../assets/svg/scheme/right.svg";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import "./style.scss";
function Arrow({ children, disabled, onClick }) {
  return (
    <button
      className={`arrow-button ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    getPrevElement,
    scrollToItem,
    visibleElements,
    initComplete,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  const clickHandler = () => scrollToItem(getPrevElement(), "smooth", "start");

  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={clickHandler}>
      <Left stroke={disabled ? "#b7b7b7" : "#1c1c1c"} />
    </Arrow>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    getNextElement,
    scrollToItem,
    visibleElements,
  } = React.useContext(VisibilityContext);
  const clickHandler = () => scrollToItem(getNextElement(), "smooth", "start");

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={clickHandler}>
      <Right stroke={disabled ? "#b7b7b7" : "#1c1c1c"} />
    </Arrow>
  );
}
