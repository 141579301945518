import "./style.scss";

export const Table = ({ columns, rows, maxWidth, isSecondActive }) => {
  return (
    <div className="table-container">
      <table
        className="table"
        style={{ maxWidth: maxWidth ? maxWidth : "100%" }}
      >
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={
                  index === 1 && isSecondActive
                    ? "second-header-column"
                    : index === 2 && isSecondActive
                    ? "third-column"
                    : ""
                }
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className={
                    cellIndex === 1 && isSecondActive
                      ? "second-column"
                      : index === 2 && isSecondActive
                      ? "third-column"
                      : ""
                  }
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
