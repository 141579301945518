export const columns2 = [
  "Parameter",
  "Mean score",
  "Std y",
  "Metric",
  "Value",
  "Fold 1",
  "Fold 2",
  "Fold 3",
  "Fold 4",
  "Fold 5",
];
export const rows2 = [
  [
    "Acute Toxicity mouse oral LD50 ",
    "0.431 ",
    "0.602",
    "RMSE ",
    "-log(mol/kg)",
    "0.432 ",
    "0.43 ",
    "0.426 ",
    "0.438 ",
    "0.426 ",
  ],
  [
    "Acute Toxicity guinea pig oral LD50 ",
    "0.754 ",
    "1.222",
    "RMSE ",
    "-log(mol/kg)",
    "0.807 ",
    "0.786 ",
    "0.762 ",
    "0.824 ",
    "0.59 ",
  ],
  [
    "Acute Toxicity rabbit oral LD50 ",
    "0.696 ",
    "0.878",
    "RMSE ",
    "-log(mol/kg)",
    "0.667 ",
    "0.678 ",
    "0.691 ",
    "0.617 ",
    "0.827 ",
  ],
  [
    "Acute Toxicity rabbit skin LD0 ",
    "0.787 ",
    "0.889",
    "RMSE ",
    "-log(mol/kg)",
    "0.868 ",
    "1.101 ",
    "0.566 ",
    "0.623 ",
    "0.777 ",
  ],
  [
    "Acute Toxicity rat intravenous LD50 ",
    "0.588 ",
    "0.982",
    "RMSE ",
    "-log(mol/kg)",
    "0.57 ",
    "0.565 ",
    "0.6 ",
    "0.664 ",
    "0.543 ",
  ],
  [
    "Acute Toxicity mouse intravenous LD50 ",
    "0.449 ",
    "0.726",
    "RMSE ",
    "-log(mol/kg)",
    "0.438 ",
    "0.44 ",
    "0.458 ",
    "0.458 ",
    "0.454 ",
  ],
  [
    "Acute Toxicity guinea pig intravenous LD50",
    "0.849 ",
    "1.274",
    "RMSE ",
    "-log(mol/kg)",
    "0.859 ",
    "0.766 ",
    "0.982 ",
    "0.861 ",
    "0.775 ",
  ],
  [
    "Acute Toxicity rabbit intravenous LD50 ",
    "0.71 ",
    "1.192",
    "RMSE ",
    "-log(mol/kg)",
    "0.747 ",
    "0.74 ",
    "0.619 ",
    "0.71 ",
    "0.734 ",
  ],
  [
    "Acute Toxicity rat skin LD50 ",
    "0.627 ",
    "0.798",
    "RMSE ",
    "-log(mol/kg)",
    "0.597 ",
    "0.583 ",
    "0.628 ",
    "0.638 ",
    "0.688 ",
  ],
  [
    "Acute Toxicity mouse skin LD50 ",
    "0.798 ",
    "0.969",
    "RMSE ",
    "-log(mol/kg)",
    "0.948 ",
    "0.84 ",
    "0.713 ",
    "0.786 ",
    "0.704 ",
  ],
  [
    "Acute Toxicity guinea pig skin LD50 ",
    "0.798 ",
    "0.985",
    "RMSE ",
    "-log(mol/kg)",
    "0.955 ",
    "0.898 ",
    "0.636 ",
    "0.754 ",
    "0.747 ",
  ],
  [
    "Acute Toxicity rabbit skin LD50 ",
    "0.564 ",
    "0.738",
    "RMSE ",
    "-log(mol/kg)",
    "0.534 ",
    "0.608 ",
    "0.573 ",
    "0.557 ",
    "0.549 ",
  ],
  [
    "Acute Toxicity rat intravenous LD0 ",
    "0.977 ",
    "1.194",
    "RMSE ",
    "-log(mol/kg)",
    "0.702 ",
    "0.772 ",
    "1.506 ",
    "0.919 ",
    "0.987 ",
  ],
  [
    "Acute Toxicity mouse intravenous LD0 ",
    "1.013 ",
    "1.217",
    "RMSE ",
    "-log(mol/kg)",
    "0.698 ",
    "1.149 ",
    "0.842 ",
    "1.265 ",
    "1.112 ",
  ],
  [
    "Acute Toxicity guinea pig intravenous LD0",
    "0.794 ",
    "1.168",
    "RMSE ",
    "-log(mol/kg)",
    "0.607 ",
    "0.665 ",
    "0.946 ",
    "0.936 ",
    "0.815 ",
  ],
  [
    "Acute Toxicity rabbit intravenous LD0 ",
    "0.884 ",
    "1.222",
    "RMSE ",
    "-log(mol/kg)",
    "0.786 ",
    "1.014 ",
    "0.96 ",
    "0.687 ",
    "0.973 ",
  ],
  [
    "Acute Toxicity rat oral LD0 ",
    "0.785 ",
    "0.881",
    "RMSE ",
    "-log(mol/kg)",
    "0.732 ",
    "0.787 ",
    "0.753 ",
    "0.782 ",
    "0.873 ",
  ],
  [
    "Acute Toxicity mouse oral LD0 ",
    "0.796 ",
    "0.864",
    "RMSE ",
    "-log(mol/kg)",
    "0.828 ",
    "0.756 ",
    "0.922 ",
    "0.723 ",
    "0.751 ",
  ],
  [
    "Acute Toxicity rabbit oral LD0 ",
    "0.729 ",
    "0.874",
    "RMSE ",
    "-log(mol/kg)",
    "0.754 ",
    "0.62 ",
    "0.732 ",
    "0.711 ",
    "0.826 ",
  ],
  [
    "Acute Toxicity rat intraperitoneal LD50 ",
    "0.599 ",
    "0.843",
    "RMSE ",
    "-log(mol/kg)",
    "0.598 ",
    "0.622 ",
    "0.591 ",
    "0.604 ",
    "0.581 ",
  ],
  [
    "Acute Toxicity mouse intraperitoneal LD50 ",
    "0.455 ",
    "0.69 ",
    "RMSE ",
    "-log(mol/kg)",
    "0.44 ",
    "0.448 ",
    "0.466 ",
    "0.459 ",
    "0.462 ",
  ],
  [
    "Blood-brain barrier permeation (BBBP) p np ",
    "0.936 ",
    " class counts 1:1475 0:460",
    "ROC-AUC ",
    "p np",
    "0.932 ",
    " 0.951",
    " 0.938",
    " 0.939 ",
    " 0.918",
  ],
  [
    "Carcinogenicity (positive carc)",
    "0.767 ",
    " class counts 1:702 0:754 ",
    "ROC-AUC ",
    "positive carc ",
    "0.749 ",
    " 0.768 ",
    " 0.811 ",
    " 0.743 ",
    " 0.761",
  ],
  [
    "AMES Mutagenicity Test (AMES Test) ",
    "0.892 ",
    " class counts 1:3490 0:3006",
    "ROC-AUC ",
    "Is active",
    "0.893 ",
    " 0.889",
    " 0.887",
    " 0.890 ",
    " 0.903",
  ],
];
