import { useMediaQuery } from "usehooks-ts";

import { ReactComponent as ArrowSteps } from "../../../assets/svg/arrow-steps.svg";
import { TextCard } from "../text-card/TextCard";
import "./style.scss";

export const ArrowsBlock = () => {
  const isTablet = useMediaQuery("(max-width: 860px)");

  return (
    <span className="arrow_steps_container">
      {isTablet ? null : (
        <span className="arrow_container">
          <ArrowSteps />
        </span>
      )}
      <span
        className={
          isTablet ? "mobile_text_cards_container" : "text_cards_container"
        }
      >
        <TextCard
          header="1st step"
          text="Structures of available substances are provided and the properties of these molecules which are necessary for further research are indicated"
        />
        <TextCard
          header="2st step"
          text={`We determine the project timeline and cost\n${
            isTablet ? "" : "\n"
          }Usually: 1 target - 1 month`}
        />
        <TextCard
          header="3st step"
          text="Virtual high-throughput screening for compounds is performed and, if necessary, patent clearance is checked"
        />
        <TextCard
          header="4st step"
          text="A list of candidates for further research is provided"
        />
      </span>
    </span>
  );
};
