import "./style.scss";

export const TextPhotoCard = (props) => {
  return (
    <div className="text_photo_card_container">
      <div className="photo_container">
        <props.photo />
      </div>
      <div className="photo_text_card_container">
        <span className="card_header_text">{props.header}</span>
        <span className="card_sub_text">{props.text}</span>
      </div>
    </div>
  );
};
