import React from 'react';
import { Button } from 'antd';
import './button.scss';

/*
 * A reusable component that renders a button with optional icon and text.
 * @param {Function} onButtonClick - The function to be called when the button is clicked.
 * @param {String} buttonClassName - The CSS class name to be applied to the button.
 * @param {String} buttonIcon - The name of the icon to be displayed on the button.
 * @param {Boolean} buttonDisabled - Whether the button is disabled or not.
 * @param {String} buttonSize - The size of the button.
 * @param {String} children - The text to be displayed on the button.
 * @param {String} htmlType - The type of the button (text, submit, etc.).
 * @returns {JSX.Element} - The button component.
 */
export const ButtonComp = ({
  onButtonClick,
  buttonClassName,
  buttonStyle,
  buttonIcon,
  buttonDisabled,
  buttonSize,
  children,
  htmlType,
}) => {
  return (
    <Button
      className={buttonClassName}
      style={buttonStyle}
      htmlType={htmlType ? htmlType : 'text'}
      type='text'
      icon={buttonIcon}
      size={buttonSize ? buttonSize : 'large'}
      onClick={onButtonClick}
      disabled={buttonDisabled}
    >
      {children}
    </Button>
  );
};
