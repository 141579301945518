import "./style.scss";

export const ArticleCard = (props) => {
  return (
    <div className="article_text_card_container">
      <a
        href={props.link}
        target="_blank"
        rel="noreferrer"
        className="card_header_text"
      >
        {props.header}
      </a>
      <span className="card_sub_text">{props.text}</span>
    </div>
  );
};
