import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import s1 from "../../../assets/svg/scheme/1.png";
import s2 from "../../../assets/svg/scheme/2.png";
import s31 from "../../../assets/svg/scheme/3.1.png";
import s32 from "../../../assets/svg/scheme/3.2.png";
import s33 from "../../../assets/svg/scheme/3.3.png";
import s34 from "../../../assets/svg/scheme/3.4.png";
import s4 from "../../../assets/svg/scheme/4.png";
import s5 from "../../../assets/svg/scheme/5.png";
import s6 from "../../../assets/svg/scheme/6.png";
import first from "../../../assets/svg/scheme/first.png";
import last from "../../../assets/svg/scheme/last.png";

import { LeftArrow, RightArrow } from "./arrows";

import "./style.scss";
import usePreventBodyScroll from "./usePreventBodyScroll";

const schemeItems2 = [s1, s2, s31, s32, s33, s34, s4, s5, s6];

const Arrows = () => (
  <div className="arrows-container">
    <LeftArrow /> <RightArrow />
  </div>
);

export const HorizontalBlock = () => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  return (
    <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
      <ScrollMenu onWheel={onWheel} Footer={Arrows}>
        {schemeItems2.map((item, index) => (
          <img src={item} key={index} height={350} alt="img" />
        ))}
      </ScrollMenu>
    </div>
  );
};

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
