import { useState, createContext, useMemo } from "react";
import { Input } from "../UI/input/Input";
import { ButtonComp } from "../button/Button";

import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as Done } from "../../assets/svg/done.svg";

import "./style.scss";
import axios from "axios";
import { notification } from "antd";

const Context = createContext({ name: "Default" });

export const Form = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");

  const [comment, setComment] = useState("");

  const [api, contextHolder] = notification.useNotification();

  const validateForm = () => {
    let isValid = true;

    // Validate email
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate name
    if (!name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Validate company
    if (!company) {
      setCompanyError("Company is required");
      isValid = false;
    } else {
      setCompanyError("");
    }

    return isValid;
  };

  const openNotification = (placement) => {
    api.success({
      message: `Done!`,
      description:
        "Thank you for contacting us, we will reply to you within 24 hours",
      icon: <Done />,
      placement,
      style: {
        boxShadow: "0px 2px 100px 0px #F4F4F4",
      },
    });
  };

  const handleError = (e) => {
    e.preventDefault();
    console.log("error");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedTime = currentDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const currentDateTime = `${formattedDate} ${formattedTime}`;

    const data = {
      Email: email,
      "Full Name": name,
      Company: company,
      Comment: comment,
      "Data and Time": currentDateTime.toString(),
    };
    const response = await axios.post(
      "https://sheet.best/api/sheets/a90d6e40-ecd0-4c04-accb-5d7aaf35037f",
      data
    );
    if (response.status === 200) {
      openNotification("bottomLeft");
      setEmail("");
      setName("");
      setCompany("");
      setComment("");
    }
  };

  const validation = (e) => {
    let isFormValid = validateForm();
    if (!isFormValid) {
      handleError(e);
      return;
    }
    handleSubmit(e);
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <form onSubmit={validation} className="form_container">
        <span className="top_level_container">
          <Input
            type="email"
            name="email"
            value={email}
            placeholder="Email"
            handleChange={(e) => setEmail(e.target.value)}
            error={emailError}
          />
          <Input
            type="text"
            name="name"
            value={name}
            placeholder="Full name"
            handleChange={(e) => setName(e.target.value)}
            error={nameError}
          />
          <Input
            type="text"
            name="company"
            value={company}
            placeholder="Company"
            handleChange={(e) => setCompany(e.target.value)}
            error={companyError}
          />
        </span>
        <span className="second_level_container">
          <Input
            type="text"
            name="comment"
            value={comment}
            placeholder="Comment (optional)"
            handleChange={(e) => setComment(e.target.value)}
            maxWidth="384"
            comment
          />
          <ButtonComp
            buttonClassName="btn_antd mobile_btn"
            buttonStyle={{ height: 56 }}
            type="text"
            htmlType="submit"
            buttonSize="large"
          >
            <span className="button-children">
              Submit <ArrowRight />
            </span>
          </ButtonComp>
        </span>
      </form>
    </Context.Provider>
  );
};
